const modal = document.querySelector("#contact_form_modal");
const openModal = document.querySelector(".btn-modal-open");
const closeModal = document.querySelector(".btn-modal-close");

openModal.addEventListener("click", () => {
  modal.showModal();
});

closeModal.addEventListener("click", () => {
  modal.setAttribute("closing", "");

  modal.addEventListener(
    "animationend",
    () => {
      modal.removeAttribute("closing");
      modal.close();
    },
    { once: true }
  );
});

modal.addEventListener("click", (event) => {
  if (event.target === modal) {
    modal.setAttribute("closing", "");

    modal.addEventListener(
      "animationend",
      () => {
        modal.removeAttribute("closing");
        modal.close();
      },
      { once: true }
    );
  }
});