// select the favicon
const faviconEl = document.querySelector('link[rel="icon"]')

// watch for changes
const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
mediaQuery.addEventListener('change', themeChange)

// listener
function themeChange(event) {
  if (event.matches) {
    faviconEl.setAttribute('href', './wp-content/themes/viridium/dist/favicons/dark-favicon.png')
  } else {
    faviconEl.setAttribute('href', './wp-content/themes/viridium/dist/favicons/light-favicon.png')
  }
}